import React from "react"
import {graphql} from "gatsby"
import {Consumer} from "src/utils/contexts"

import SEO from "src/components/Element/seo"
import PostArticle from "src/components/Domain/Post/post_article"
import PostListRelated from "src/components/Domain/Post/post_list_related"

import "./index.css"

// const PostTemplate = ({data, pageContext, location}) => {
export default class PostTemplate extends React.Component {

    render() {
        const data = this.props.data
        const pageContext = this.props.pageContext

        if (! data.allStrapiPost.edges[0]) {
            return (<>No Post</>)
        }
        const node = data.allStrapiPost.edges[0].node
        const { previous, next } = pageContext
        // const thumbnail_path = node.thumbnail[0] ? node.thumbnail[0].url : null
        const thumbnail_path = node.thumbnail ? node.thumbnail.childImageSharp.fluid.src : null

        return (
            <Consumer>
                {({store, functions}) => (
                    <>
                        <SEO
                            title={node.title + " - 千のおうち"}
                            description={node.summary}
                            image_uri={thumbnail_path ? store.config.api_uri + thumbnail_path : null}
                        />
                        <div id="article" className="main-content">
                            <PostArticle node={node} />
                        </div>
                        <PostListRelated node={node} previous={previous} next={next} />
                    </>
                )}
            </Consumer>
        )
    }
}

// export default PostTemplate

export const pageQuery = graphql`
    query PostByDateAndTitle($date_start: Date! $date_end: Date! $uri: String!) {
        site {
            siteMetadata {
                title
            }
        }
        allStrapiPost(
            filter: {
                visible: {eq: true}
                date: {gte: $date_start lte: $date_end}
                uri: {eq: $uri}
            }
        ) {
            edges {
                node {
                    id
                    title
                    date
                    content
                    uri
                    thumbnail {
                        childImageSharp {
                            fluid(maxWidth: 480) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    tags {
                        id
                        Name
                        visible
                    }
                    related_to {
                        id
                        title
                        date
                        content
                        uri
                        thumbnail {
                            childImageSharp {
                                fluid(maxWidth: 480) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    visible
                }
            }
        }
    }
`

import React from "react";
import {Link} from "gatsby";

import {Consumer} from "src/utils/contexts"
import Horizontal from "src/components/Element/horizontal"

import makePostListItemArray from "src/utils/make_post_list_item_array"

import "./index.css"

export default class PostListRelated extends React.Component {

    state = {
        related_show: false
    }

    isBottom(el) {
        return el.getBoundingClientRect().bottom <= window.innerHeight
    }
    
    componentDidMount() {
        document.addEventListener("scroll", this.trackScrolling)
        // this.trackScrolling()
    }
    
    componentWillUnmount() {
        document.removeEventListener("scroll", this.trackScrolling)
    }

    trackScrolling = () => {
        const wrappedElement = document.getElementById("artcile_content")
        if( ! wrappedElement) {
            return false
        }
        this.isBottom(wrappedElement)
        if (this.isBottom(wrappedElement)) {
            document.removeEventListener("scroll", this.trackScrolling)

            // show related
            this.showRelated()
        }
    }

    showRelated = () => {
        this.setState({related_show: true})
    }

    toggleRelated = () => {
        this.setState({related_show: ! this.state.related_show})
    }

    render() {

        const node = this.props.node

        const posts = []
        node.related_to.forEach(post => {
            if(post.thumbnail && post.date) { posts.push(post) }
        })

        const previous_path = this.props.previous ? this.props.previous.path : "/" // null をそのまま渡すとLinkコンポーネントでエラーがでるので "/"
        const next_path = this.props.next ? this.props.next.path : "/"
        const no_related_class = posts.length === 0 ? "np" : ""

        return (
            <Consumer>
                {({store, functions}) => (
                    <>
                        {(() => {
                            const post_list_jsx = makePostListItemArray(store, posts)

                            return (
                                <div id="related_list" className={"related-list tc-bg " + (this.state.related_show ? "show" : "hide") + " " + no_related_class}>
                                    <div className="ri-title"
                                         onClick={this.toggleRelated}
                                         style={post_list_jsx.length > 0 ? null : {display: "none"}}
                                    />
                                    <div className="ri-article-list-wrapper" style={post_list_jsx.length > 0 ? null : {display: "none"}}>
                                        <div className={"related-horizontal"}>
                                            <Horizontal>
                                                {post_list_jsx}
                                            </Horizontal>
                                        </div>
                                    </div>
                                    <div className="ri-arrows">
                                        <Link to={next_path}
                                              className={"ri-arrow ri-arrow-left"}
                                              style={next_path !== "/" ? null : {display: "none"}}
                                        />
                                        <Link to={previous_path}
                                              className={"ri-arrow ri-arrow-right"}
                                              style={previous_path !== "/" ? null : {display: "none"}}
                                        />
                                    </div>
                                    <div className={"ri-line"} />
                                </div>
                            )
                        })()}
                    </>
                )}
            </Consumer>
        )
    }
}

